<template>
  <div class="home">
    <FinanceModule title="财务统计" :merchantShopInfo="merchantShopInfo"></FinanceModule>
    <UserModule title="用户统计" :merchantShopInfo="merchantShopInfo"></UserModule>
    <section class="sell">
      <CardSell title="top20卡券销售统计" :merchantShopInfo="merchantShopInfo"></CardSell>
      <RoomSell title="位置销售统计" :merchantShopInfo="merchantShopInfo"></RoomSell>
    </section>
  </div>
</template>

<script>
import FinanceModule from "@/views/home/FinanceModule.vue";
import UserModule from "@/views/home/UserModule.vue";
import CardSell from "@/views/home/CardSell.vue";
import RoomSell from "@/views/home/RoomSell.vue";
export default {
  components: { FinanceModule, UserModule, CardSell, RoomSell },
  data() {
    return {
      merchantShopInfo: {
        merchantId: "",
        shopId: "",
        merchantName: "",
        shopName: "",
        merchantIdSet: [],
        shopIdSet: [],
      },
    }
  },
  created() {
    let merchantShopInfo = JSON.parse(window.localStorage.getItem('playOne-store-merchantShopInfo'));
    if (merchantShopInfo) {
      merchantShopInfo.merchantIdSet = [merchantShopInfo.merchantId]
      merchantShopInfo.shopIdSet = [merchantShopInfo.shopId]
      this.merchantShopInfo = merchantShopInfo;
    }
  },
}
</script>

<style lang="scss" scoped>
.home {
  background: #f0f2f5;
}

.sell {
  display: flex;
  justify-content: space-between;
}
</style>