<template>
  <div class="card-sell">
    <h1 class="head">
      <div class="font-18">{{ title }}</div>
      <div class="pointer">总计已售额：<span class="total">¥{{ totalAmount }}</span></div>
    </h1>
    <section class="search">
      <el-radio-group class="mr15 mb15" v-model="tableParams.searchDayType" @change="onTab">
        <el-radio-button v-for="item in ObjToOpt(timeSellObj)" :key="item.value" :label="item.value">{{ item.label
        }}</el-radio-button>
      </el-radio-group>
      <el-date-picker class="date mb15" v-model="dateToDate" type="daterange" range-separator="至" start-placeholder="开始日期"
        end-placeholder="结束日期" value-format="yyyy-MM-dd" @change="onDate" :clearable="false">
      </el-date-picker>
    </section>
    <section class="list">
      <template v-if="cardList.length > 0">
        <li class="list-item" v-for="item in cardList" :key="item.cardId">
          <div class="list-L">
            <span>{{ item.cardName || "-" }}</span>
            <span>&nbsp;|&nbsp;</span>
            <span>￥{{ item.amount || "0" }}</span>
          </div>
          <div class="list-R mr15">
            <span>已售：¥{{ item.totalAmount || 0 }}</span>
            <span>&nbsp;|&nbsp;</span>
            <span class="grey">{{ item.num || 0 }}张</span>
          </div>
        </li>
      </template>
      <el-empty v-else description="暂无数据"></el-empty>
    </section>
  </div>
</template>

<script>
import { ObjToOpt } from "@/utils/utils";
import { timeSellObj } from "@/db/objs";
import { getCardList } from "@/api/home/home";
export default {
  props: ['title', 'merchantShopInfo'],
  data() {
    return {
      ObjToOpt,
      timeSellObj,
      dateToDate: [],
      tableParams: {
        searchDayType: "1",
        searchStartTime: "",
        searchEndTime: "",
      },
      cardList: [],
      totalAmount: "",
    }
  },
  created() {
    Object.assign(this.tableParams, this.merchantShopInfo);
    this.getCardList();
  },
  methods: {
    // 【请求】卡券销售统计
    getCardList() {
      if (this.dateToDate) {
        this.tableParams.searchStartTime = this.dateToDate[0];
        this.tableParams.searchEndTime = this.dateToDate[1];
      } else {
        this.tableParams.searchStartTime = "";
        this.tableParams.searchEndTime = "";
      }
      let data = this.tableParams;
      getCardList(data).then((res) => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            if (res.data.cardStatisticVos && res.data.cardStatisticVos.list) {
              this.cardList = res.data.cardStatisticVos.list;
            }else{
              this.cardList = []
            }
            if (res.data.totalAmount) {
              this.totalAmount = res.data.totalAmount;
            }else{
              this.totalAmount = 0
            }
          }
        }
      });
    },

    // 【监听】日期选择
    onDate() {
      this.tableParams.searchDayType = "";
      console.log('this.tableParams.searchDayType: ', this.tableParams.searchDayType);
      this.getCardList();
    },

    // 切换tab
    onTab(searchDayType) {
      this.tableParams.searchDayType = searchDayType;
      this.dateToDate = [];
      this.getCardList();
    }
  }
}
</script>

<style lang="scss" scoped>
.card-sell {
  background: #fff;
  padding: .15rem;
  width: calc(50% - .075rem);
  margin-right: .075rem;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .15rem;
}

.font-18 {
  font-size: .18rem;
}

.total {
  font-size: .2rem;
  margin-right: .15rem;
}

.search {
  padding-bottom: .15rem;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.list {
  max-height: 3.6rem;
  overflow: auto;
  padding: .15rem;
}

.list-item {
  display: flex;
  justify-content: space-between;
  margin: .2rem 0;
  font-size: 14px;
}

.list-L,
.list-R {
  display: flex;
  align-items: center;

  span {
    height: 100%;
    line-height: 100%;
  }
}

.date{
  width: 3.5rem;
}

.grey {
  color: rgba(144, 145, 153, 1);
}
</style>