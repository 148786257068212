<template>
  <div class="finance">
    <h1 class="head">
      <div class="font-18">{{ title }}</div>
    </h1>
    <section class="search">
      <div class="search-L">
        <el-radio-group v-model="tableParams.searchDayType" class="mr15" @change="onTab">
          <el-radio-button v-for="item in ObjToOpt(timeFrameObj)" :key="item.value" :label="item.value">{{ item.label
          }}</el-radio-button>
        </el-radio-group>
        <el-date-picker v-model="dateToDate" type="daterange" range-separator="至" start-placeholder="开始日期"
          end-placeholder="结束日期" value-format="yyyy-MM-dd" @change="onDate" :clearable="false">
        </el-date-picker>
      </div>
      <div class="search-R">
        <el-popover
          placement="top"
          trigger="click"
          content="净总收入=微信净收入+线下净收入+团购净收入+本金净收入"
        >
          <i class="el-icon-warning-outline" slot="reference"></i>
        </el-popover>
        <div class="pointer mr15" @click="onTable('', '净总收入')">净总收入：<span class="total">{{ totalCount }}</span><i
            class="el-icon-arrow-right"></i>
        </div>
      </div>
    </section>
    <section class="tofu">
      <ul class="tofu-ul bg-top">
        <li class="tofu-li" v-for="item in homeFinanceTopObj" :key="item.title" @click="onTable(item.type, item.title)">
          <p class="title mb15"><i class="mr15 icons" :class="item.icon"></i>{{ item.title }}<i
              class="el-icon-arrow-right"></i></p>
          <p class="count line-top">{{ item.count }}</p>
          <p class="refund"><span class="refund-s">退</span>
            <span v-if="item.refund > 0">－</span>{{ item.refund }}
          </p>
        </li>
      </ul>
      <div class="tofu-trend bg-top">
        <FinanceWayChart chartName="收款方式趋势分析" :merchantShopInfo="merchantShopInfo"></FinanceWayChart>
      </div>
    </section>
    <section class="tofu">
      <ul class="tofu-ul bg-bottom">
        <li class="tofu-li" v-for="item in homeFinanceBottomObj" :key="item.title">
          <p class="title mb15"><i class="mr15 icons" :class="item.icon"></i>{{ item.title }}</p>
          <p class="count line-bottom">{{ item.count }}</p>
          <p class="refund"><span class="refund-s">退</span>
            <span v-if="item.refund > 0">－</span>{{ item.refund }}
          </p>
        </li>
      </ul>
      <div class="tofu-trend bg-bottom">
        <FinanceTypeChart chartName="收入项趋势分析" :merchantShopInfo="merchantShopInfo"></FinanceTypeChart>
      </div>
    </section>
    <!-- 编辑弹框 -->
    <FinanceTable :merchantShopInfo="merchantShopInfo" ref="child"></FinanceTable>
  </div>
</template>

<script>
import { getPayChannelCube } from "@/api/home/home";
import { ObjToOpt } from "@/utils/utils";
import { timeFrameObj, homeFinanceTopObj, homeFinanceBottomObj } from "@/db/objs";
import FinanceTable from "@/views/home/child/FinanceTable.vue";
import FinanceWayChart from "@/views/home/child/FinanceWayChart.vue";
import FinanceTypeChart from "@/views/home/child/FinanceTypeChart.vue";
export default {
  name:"FinanceModule",
  props: ['title', 'merchantShopInfo'],
  components: { FinanceTable, FinanceWayChart, FinanceTypeChart },
  data() {
    return {
      ObjToOpt,
      timeFrameObj,
      homeFinanceTopObj,
      homeFinanceBottomObj,
      totalCount: "",
      dateToDate: [],
      tableParams: {
        searchDayType: 1,
        searchStartTime: "",
        searchEndTime: "",
      },
    }
  },
  created() {
    Object.assign(this.tableParams, this.merchantShopInfo);
    this.getPayChannelCube();
  },
  methods: {
    // 【请求】财务统计豆腐块
    getPayChannelCube() {
      if (this.dateToDate) {
        this.tableParams.searchStartTime = this.dateToDate[0];
        this.tableParams.searchEndTime = this.dateToDate[1];
      } else {
        this.tableParams.searchStartTime = "";
        this.tableParams.searchEndTime = "";
      }
      let data = this.tableParams;
      getPayChannelCube(data).then((res) => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            for (let i of homeFinanceTopObj) {
              i.count = res.data[i.name];
              i.refund = res.data[i.refundName];
            };
            for (let i of homeFinanceBottomObj) {
              i.count = res.data[i.name];
              i.refund = res.data[i.refundName];
            };
            this.totalCount = res.data.totalCount;
          }
        }
      });
    },

    // 【监听】日期选择
    onDate() {
      this.tableParams.searchDayType = "";
      this.getPayChannelCube();
    },

    // 切换tab
    onTab(searchDayType) {
      this.tableParams.searchDayType = searchDayType;
      this.dateToDate = [];
      this.getPayChannelCube();
    },

    // 【监听】收入列表
    onTable(type, title) {
      this.$refs.child.onInit(type, title);
    }
  }
}
</script>

<style lang="scss" scoped>
.finance {
  background: #fff;
  padding: .15rem;
  margin-bottom: .15rem;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .15rem;
}

.font-18 {
  font-size: .18rem;
}

.search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .15rem;
}


.pointer {
  margin-left: 0.1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.tofu {
  margin-bottom: .15rem;
  display: flex;
  justify-content: space-between;
}

.tofu-ul {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: .15rem;
  padding: .15rem;
  border-radius: .05rem;
  flex-wrap: wrap;
  &:after {
    content: "";
    width: 32%;
  }
}

.tofu-li {
  width: 32%;
}

.tofu-trend {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: .15rem;
  position: relative;
  border-radius: .05rem;
}

.arrows {
  position: absolute;
  top: 40%;
  right: -10%;
}

.bg-top {
  background: rgba(239, 152, 7, 0.02);
  border: 1px solid rgba(239, 152, 7, 0.30);
  cursor: pointer;
}

.bg-bottom {
  border: 1px solid rgba(238, 238, 238, 1);
}

.icons {
  font-size: .2rem;
  color: #EF9807;
}

.title {
  color: rgba(52, 54, 67, 1);
  display: flex;
  align-items: center;
}

.count {
  font-size: .4rem;
  height: .55rem;
  line-height: .55rem;
  display: flex;
  justify-content: center;
  margin-bottom: .1rem;
}

.refund {
  font-size: .14rem;
  color: rgba(255, 25, 25, 1);
  margin-bottom: .15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.line-top {
  border-right: 1px solid rgba(239, 152, 7, 0.30);
}

.tofu-li:nth-child(3) .line-top {
  border: 0;
}

.line-bottom {
  border-right: 1px solid rgba(238, 238, 238, 1);
}

.tofu-li:last-child .line-bottom {
  border: 0;
}

.refund-s {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 25, 25, 0.06);
  width: .22rem;
  height: .22rem;
  border-radius: 50%;
  margin-right: .15rem;
  font-size: .12rem;
}

.total {
  font-size: .24rem;
}
.search-R {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>