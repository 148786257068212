<template>
  <div class="page" @click="onChart">
    <i class="iconfont icon-icon_qsfx mb15 icons"></i>
    <p class="column">趋</p>
    <p class="column">势</p>
    <p class="column">分</p>
    <p class="column">析</p>
    <!-- 编辑弹框 -->
    <el-dialog
      :title="chartName"
      :visible.sync="editShow"
      :close-on-click-modal="false"
      append-to-body
      width="60%"
    >
      <!-- 查询 -->
      <section class="table-search">
        <div class="search-L">
          <el-date-picker
            v-model="dateToDate"
            class="mr15"
            type="daterange"
            range-separator="至"
            start-placeholder="近30日开始日期"
            end-placeholder="近30日结束日期"
            value-format="yyyy-MM-dd"
            @change="onDate"
            :clearable="false"
          >
          </el-date-picker>
        </div>
        <div class="search-R"></div>
      </section>
      <section class="counts">
        <p>
          <b>所属商户：{{ merchantShopInfo.merchantName }}</b>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <b>所属门店：{{ merchantShopInfo.shopName }}</b>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <b>新增用户：{{ userTotal.addNum }}</b>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <b>活跃用户：{{ userTotal.updateNum }}</b>
        </p>
      </section>
      <section class="chart">
        <div id="myEchart" ref="myEchartA"></div>
      </section>
    </el-dialog>
  </div>
</template>


<script>
import { getUserLine, getUserTotal } from "@/api/home/home";
import * as echarts from "echarts";
export default {
  props: ["chartName", "merchantShopInfo"],
  data() {
    return {
      // 编辑弹框
      editShow: false, //弹框开关
      dateToDate: [],
      tableParams: {
        searchStartTime: "",
        searchEndTime: "",
      },
      userInfo: "",
      myEchartA: "",
      userTotal: "",
    };
  },
  computed: {
    // 日期
    dateList() {
      let arr = [];
      if (this.userInfo) {
        for (let i of this.userInfo) {
          arr = [...arr, i.date];
        }
      }
      return arr;
    },
    // 新增用户
    addNum() {
      let arr = [];
      if (this.userInfo) {
        for (let i of this.userInfo) {
          arr = [...arr, i.addNum];
        }
      }
      return arr;
    },
    // 活跃用户
    updateNum() {
      let arr = [];
      if (this.userInfo) {
        for (let i of this.userInfo) {
          arr = [...arr, i.updateNum];
        }
      }
      return arr;
    },
  },
  methods: {
    initTime() {
      let end = new Date();
      let start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      this.dateToDate = [start, end];
    },
    // 【渲染】折线图
    myChartFnA() {
      if (this.myEchartA == "") {
        this.myEchartA = echarts.init(this.$refs.myEchartA);
      }
      let option = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.dateList,
        },
        tooltip: {
          trigger: "axis",
        },
        color: ["#4870FF", "#6DBE00"],
        yAxis: {
          type: "value",
        },
        legend: {
          data: ["新增用户", "活跃用户"],
        },
        series: [
          {
            name: "新增用户",
            data: this.addNum,
            type: "line",
            smooth: true,
          },
          {
            name: "活跃用户",
            data: this.updateNum,
            type: "line",
            smooth: true,
          },
        ],
      };
      this.myEchartA.setOption(option);
    },

    // 【请求】用户项趋势分析
    getUserLine() {
      if (this.dateToDate) {
        this.tableParams.searchStartTime = this.dateToDate[0];
        this.tableParams.searchEndTime = this.dateToDate[1];
      } else {
        this.tableParams.searchStartTime = "";
        this.tableParams.searchEndTime = "";
      }

      let data = this.tableParams;
      getUserLine(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.userInfo = res.data;
          this.$nextTick(() => {
            this.myChartFnA();
          });
        }
      });
    },

    // 【请求】用户统计折线图总量
    getUserTotal() {
      if (this.dateToDate) {
        this.tableParams.searchStartTime = this.dateToDate[0];
        this.tableParams.searchEndTime = this.dateToDate[1];
      } else {
        this.tableParams.searchStartTime = "";
        this.tableParams.searchEndTime = "";
      }
      let data = this.tableParams;
      getUserTotal(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.userTotal = res.data;
        }
      });
    },

    //【监听】打开图表
    onChart() {
      Object.assign(this.tableParams, this.merchantShopInfo);
      this.editShow = true;
      this.getUserLine();
      this.getUserTotal();
      this.initTime();
    },

    // 【监听】日期选择
    onDate() {
      this.getUserLine();
      this.getUserTotal();
    },
  },
};
</script>


<style lang="scss" scoped>
.page {
  padding: 0.15rem 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icons {
  font-size: 0.2rem;
  color: #ef9807;
  margin-bottom: 0.15rem;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.table-search {
  margin-bottom: 0.15rem;
  display: flex;
  justify-content: space-between;
}

.search-L {
  display: flex;
}

.chart {
  display: flex;
  width: 100%;
}

#myEchart {
  width: 100%;
  height: 4rem;
}

.counts {
  display: flex;
  margin-bottom: 0.15rem;
}
</style>